import { defineCustomElement, BaseController, attachMediaAttributes } from '@mrhenry/wp--custom-elements-helpers';
import dynamicProperties from '@mrhenry/wp--dynamic-properties';

class TooltipController extends BaseController {
	resolve() {
		return Promise.all( [
			super.resolve(),
			this.whenMediaMatches().then( () => {
				this.hasShown = true;
			} ),
		] );
	}

	init() {
		this.elements = {};
		this.elements.parent = this.el.parentNode;

		this.settings = {};
		this.settings.offset = 16; // Offset between cursor and tooltips
		this.settings.margin = 40; // Minimum margin between tooltip & browser border

		this.el.style.display = ''; // Avoid display:none to have correct width & height
		const boundingBox = this.el.getBoundingClientRect();

		this.dimensions = dynamicProperties( {
			windowWidth: () => {
				return window.innerWidth;
			},
			windowHeight: () => {
				return window.innerHeight;
			},
			width: () => {
				return Math.ceil( boundingBox.width );
			},
			height: () => {
				return Math.ceil( boundingBox.height );
			},
		} );
	}

	bind() {
		this.on( 'mouseenter', () => {
			this.show();
		}, this.elements.parent, {
			passive: true,
		} );

		this.on( 'mouseleave', () => {
			this.hide();
		}, this.elements.parent, {
			passive: true,
		} );

		this.on( 'mousemove', ( e ) => {
			const {
				x, y,
			} = this.calculatePosition( e.clientX, e.clientY );

			window.requestAnimationFrame( () => {
				this.el.style.transform = `translate3d(${x}px, ${y}px, 0)`;
			} );
		}, this.elements.parent, {
			passive: true,
		} );

		return this;
	}

	render() {
		this.el.style.display = 'none';
	}

	destroy() {
		super.destroy();

		if ( this.hasShown ) {
			this.hide();
			this.el.style.display = '';
		}
	}

	isInHorizontalMargin( x ) {
		const max = this.dimensions.windowWidth - this.settings.margin;
		const compare = x + this.settings.offset + this.dimensions.width;

		return ( compare > max );
	}

	isInVerticalMargin( y ) {
		const max = this.dimensions.windowHeight - this.settings.margin;
		const compare = y + this.settings.offset + this.dimensions.height;

		return ( compare > max );
	}

	calculatePosition( mouseX, mouseY ) {
		const {
			offset,
		} = this.settings;
		const {
			width, height,
		} = this.dimensions;

		let x = mouseX;
		let y = mouseY;

		if ( this.isInHorizontalMargin( mouseX ) ) {
			x = x - width - offset;
		} else {
			x += offset;
		}

		if ( this.isInVerticalMargin( mouseY ) ) {
			y = y - height - offset;
		} else {
			y += offset;
		}

		return {
			x,
			y,
		};
	}

	show() {
		this.isShowing = true;

		window.requestAnimationFrame( () => {
			Object.assign( this.el.style, {
				display: '',
				position: 'fixed',
				top: 0,
				left: 0,
				visibility: 'visible',
			} );
		} );
	}

	hide() {
		this.isShowing = false;

		window.requestAnimationFrame( () => {
			Object.assign( this.el.style, {
				display: 'none',
				position: '',
				top: '',
				left: '',
				transform: '',
				visibility: 'hidden',
			} );
		} );
	}
}

attachMediaAttributes( TooltipController );

defineCustomElement( 'mr-tooltip', {
	attributes: [],
	controller: TooltipController,
} );
