import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-busta', {
	attributes: [
		{
			attribute: 'mr-henry',
			type: 'boolean',
		},
	],
	controller: class extends BaseController {
		init() {
			requestAnimationFrame( () => {
				this.start();
			} );
		}

		destroy() {
			if ( this.instance ) {
				this.instance.destroy();
			}

			super.destroy();
		}

		async start() {
			try {
				await this.loadJS();

				const canvas = this.el.getElementsByTagName( 'canvas' ).item( 0 );

				let models = [
					'head.obj',
					'head21.obj',
					'model1.obj',
					'model2.obj',
					'model3.obj',
					'model6.obj',
					'model7.obj',
				];

				if ( this.mrHenry ) {
					models = [
						'mrhenry-hans.obj',
						'mrhenry-inge.obj',
						'mrhenry-marian.obj',
						'mrhenry-pieter.obj',
						'mrhenry-romain.obj',
						'mrhenry-wout.obj',
						'mrhenry-yves.obj',
					];
				}

				const shuffled = models.map( ( filename ) => {
					return `${window.ASSET_URL}/assets/files/models/${filename}`;
				} ).sort( () => {
					return 0.5 - Math.random();
				} );

				this.instance = new window.LAB101_FLOATING_OBJECTS( canvas, shuffled );

			} catch ( err ) {
				// non critical content
				console.warn( err );
			}
		}

		loadJS() {
			return new Promise( ( resolve, reject ) => {
				const script = document.createElement( 'script' );
				script.src = `${window.ASSET_URL}/assets/files/busta.min.js`;
				script.onload = () => {
					return resolve();
				};
				script.onerror = () => {
					return reject( new Error( 'busta js failed to load' ) );
				};
				script.async = true;

				const first = document.head.getElementsByTagName( 'script' )[0];
				first.parentNode.insertBefore( script, first );
			} );
		}
	},
} );
