import { addMethod, addGetter } from '../internal/decorators';
import { BaseController } from '../controllers/base';

interface ControllerWithMediaAttributes<T extends HTMLElement> extends BaseController<T|HTMLElement> {
	media: string | null
	matchesMedia: boolean
	whenMediaMatches: () => void
	whenMediaUnmatches: () => void
	watchMedia: (
		match: () => void,
		unmatch: () => void
	) => void
}

export function attachMediaAttributes<T extends BaseController<U|HTMLElement>, U extends HTMLElement>( controllerType: new ( el: U | HTMLElement ) => T ): void {
	const watchers : Map<string, MediaQueryList> = new Map();

	// Adds customElement.media
	// @return string 		Value of `media=""` attribute
	addGetter( controllerType, 'media', function( this: ControllerWithMediaAttributes<U> ): string | null {
		return this.el.getAttribute( 'media' );
	} );

	// Adds customElement.matchesMedia
	// @return bool 		If the viewport currently matches the specified media query
	addGetter( controllerType, 'matchesMedia', function( this: ControllerWithMediaAttributes<U> ) {
		const mediaToMatch = this.media;
		if ( !mediaToMatch ) {
			return true;
		}

		return 'matchMedia' in window && !!window.matchMedia( mediaToMatch ).matches;
	} );

	// Adds customElements.whenMediaMatches()
	// @return Promise
	addMethod( controllerType, 'whenMediaMatches', function whenMediaMatches( this: ControllerWithMediaAttributes<U> ) {
		const defer = new Promise<void>( ( resolve ) => {
			const mediaToMatch = this.media;
			if ( !mediaToMatch ) {
				resolve();

				return;
			}

			if ( !( 'matchMedia' in window ) ) {
				resolve();

				return;
			}

			let watcher : MediaQueryList;
			if ( watchers.has( mediaToMatch ) ) {
				watcher = watchers.get( mediaToMatch ) || window.matchMedia( mediaToMatch );
			} else {
				watcher = window.matchMedia( mediaToMatch );
			}

			if ( watcher.matches ) {
				resolve();

				return;
			}

			const handler = (): void => {
				if ( watcher.matches ) {
					resolve();
					watcher.removeListener( handler );
				}
			};

			watcher.addListener( handler );
			watchers.set( mediaToMatch, watcher );
		} );

		return defer;
	} );

	// Adds customElements.whenMediaUnmatches()
	// @return Promise
	addMethod( controllerType, 'whenMediaUnmatches', function whenMediaUnmatches( this: ControllerWithMediaAttributes<U> ) {
		const defer = new Promise<void>( ( resolve ) => {
			const mediaToMatch = this.media;
			if ( !mediaToMatch ) {
				// hang to have reversed logic from "whenMediaMatches"
				return;
			}

			if ( !( 'matchMedia' in window ) ) {
				// hang to have reversed logic from "whenMediaMatches"
				return;
			}

			let watcher : MediaQueryList;
			if ( watchers.has( mediaToMatch ) ) {
				watcher = watchers.get( mediaToMatch ) || window.matchMedia( mediaToMatch );
			} else {
				watcher = window.matchMedia( mediaToMatch );
			}

			if ( !watcher.matches ) {
				resolve();

				return;
			}

			const handler = (): void => {
				if ( !watcher.matches ) {
					resolve();
					watcher.removeListener( handler );
				}
			};

			watcher.addListener( handler );
			watchers.set( mediaToMatch, watcher );
		} );

		return defer;
	} );

	addMethod(
		controllerType,
		'watchMedia',
		function watchMedia( this: ControllerWithMediaAttributes<U>,
			match = function() {
				return;
			},
			unmatch = function() {
				return;
			} ) {

			const mediaToMatch = this.media;
			if ( !mediaToMatch ) {
				return;
			}

			if ( !( 'matchMedia' in window ) ) {
				return;
			}

			let watcher : MediaQueryList;
			if ( watchers.has( mediaToMatch ) ) {
				watcher = watchers.get( mediaToMatch ) || window.matchMedia( mediaToMatch );
			} else {
				watcher = window.matchMedia( mediaToMatch );
			}

			if ( watcher.matches ) {
				match();
			} else {
				unmatch();
			}

			const handler = (): void => {
				if ( watcher.matches ) {
					match();
				} else {
					unmatch();
				}
			};

			watcher.addListener( handler );
			watchers.set( mediaToMatch, watcher );
		}
	);
}
