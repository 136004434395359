import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-anchor-scroll', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.links = this.el.querySelectorAll( 'a[href^="#credits"]' );
		}

		bind() {
			if ( this.elements.links ) {
				this.elements.links.forEach( ( link ) => {
					const href = link.getAttribute( 'href' );
					const target = document.querySelector( href );
					if ( target ) {
						this.on( 'click', ( e ) => {
							e.preventDefault();

							const options = {
								block: 'center',
								inline: 'center',
								behavior: 'smooth',
							};

							try {
								target.scrollIntoView( options );
							} catch ( err ) {
								target.scrollIntoView( {
									behavior: 'smooth',
								} );
							}
						}, link );
					}
				} );
			}
		}
	},
} );
