import { smoothState, defineCustomElement } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-smooth-state', {
	attributes: [
		...smoothState.attributes,
	],
	controller: class extends smoothState.controller {
		resolve() {
			if ( 'ontouchstart' in window && 768 > window.innerWidth ) {
				return new Promise( () => {} ); // never resolves
			}

			return super.resolve();
		}

		onBefore( transition ) {
			return new Promise( ( resolve ) => {
				this.once( 'transitionend', () => {
					resolve( transition );
				}, this.el );

				window.requestAnimationFrame( () => {
					Object.assign( this.el.style, {
						opacity: 0,
					} );
				} );
			} );
		}

		onReady( transition ) {
			const {
				from,
			} = transition.path;
			const {
				content,
			} = transition.fetched;

			const back = content.getElementsByClassName( 'js-smooth-state-back' ).item( 0 );

			if ( back && from ) {
				back.setAttribute( 'href', from );
			}

			return new Promise( ( resolve ) => {
				window.requestAnimationFrame( () => {
					document.body.classList.remove( 'has-media-hover' );

					const bodyClasses = Array.from( document.body.classList, ( className ) => {
						// Clear t-* classes from the body
						if ( className.startsWith( 't-' ) ) {
							return undefined;
						}

						// Always remove .has-media-hover on page transition
						if ( 'has-media-hover' === className ) {
							return undefined;
						}

						return className;
					} ).filter( ( c ) => {
						return !!c;
					} );

					// Find t-* classes on smooth state container
					const themes = Array.from( content.classList ).filter( ( c ) => {
						return c.startsWith( 't-' );
					} );

					// Copy t-* classes to body
					themes.forEach( ( theme ) => {
						if ( !bodyClasses.includes( theme ) ) {
							bodyClasses.push( theme );
						}
					} );

					document.body.className = bodyClasses.join( ' ' );

					this.el.className = content.className;

					resolve( transition );
				} );
			} );
		}

		onAfter( transition ) {
			window.scrollTo( 0, 0 );

			return new Promise( ( resolve ) => {
				this.once( 'transitionend', () => {
					resolve( transition );
					this.emit( 'smooth-state:after' );
				}, this.el );

				window.requestAnimationFrame( () => {
					Object.assign( this.el.style, {
						opacity: 1,
					} );
				} );
			} );
		}
	},
} );
