import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-infinite-scroll', {
	attributes: [
		{
			attribute: 'clone',
			type: 'boolean',
		},
	],
	controller: class extends BaseController {
		resolve() {
			if ( this.clone ) {
				return new Promise( () => {} ); // never resolves
			}

			if ( !( 'IntersectionObserver' in window ) ) {
				return new Promise( () => {} ); // never resolves
			}

			return super.resolve();
		}

		render() {
			Object.assign( this.el.style, {
				'min-height': '100vh',
			} );

			const clone = this.el.cloneNode( true );

			clone.setAttribute( 'clone', 'clone' );

			if ( this.el.nextSibling ) {
				this.el.parentNode.insertBefore( clone, this.el.nextSibling );
			} else {
				this.el.parentNode.appendChild( clone );
			}

			Object.assign( clone.style, {
				'max-height': 'calc(100vh + 1px)',
				position: 'relative',
			} );

			const trigger = document.createElement( 'div' );
			Object.assign( trigger.style, {
				opacity: 0,
				position: 'absolute',
				height: '20px',
				top: '100vh',
				width: '100vw',
				'background-color': 'red',
			} );

			clone.appendChild( trigger );

			const observer = new IntersectionObserver( ( entries ) => {
				const [
					entry,
				] = entries;

				if ( entry && entry.isIntersecting ) {
					const top = entry.boundingClientRect?.top || 0;
					const windowHeight = entry.rootBounds?.height || window.innerHeight;

					const newY = windowHeight - top;

					if ( 0 <= newY ) {
						window.requestAnimationFrame( () => {
							window.scrollTo( 0, newY );
						} );
					}
				}
			} );

			observer.observe( trigger );
		}
	},
} );
