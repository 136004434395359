import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
import dynamicProperties from '@mrhenry/wp--dynamic-properties';

defineCustomElement( 'mr-cursor', {
	attributes: [],
	controller: class extends BaseController {
		resolve() {
			return super.resolve().then( () => {
				this.isTouch = ( 'ontouchstart' in window );

				if ( this.isTouch ) {
					document.body.classList.add( 'has-touch-cursor' );
				}

				document.body.classList.add( 'has-cursor' );
			} );
		}

		init() {
			this.dimensions = dynamicProperties( {
				windowHeight: () => {
					return window.innerHeight;
				},
			} );

			this.elements = {};
			this.elements.sound = this.el.querySelector( '.js-cursor-sound' );
		}

		bind() {
			if ( this.isTouch ) {
				this.bindTouch();
			} else {
				this.bindPointer();
			}
		}

		bindTouch() {
			const selectors = [
				'.js-cursor-interaction',
			];

			const elements = Array.from( document.body.querySelectorAll( selectors.join( ',' ) ) );

			const hovered = new Set();

			this.on( 'scroll', () => {
				const {
					windowHeight,
				} = this.dimensions;

				const triggerEnterEvent = [];
				const triggerLeaveEvent = [];

				elements.forEach( ( element ) => {
					const {
						top, bottom,
					} = element.getBoundingClientRect();

					if ( top <= windowHeight / 2 && bottom >= windowHeight / 2 ) {
						if ( !hovered.has( element ) ) {
							triggerEnterEvent.push( element );
							hovered.add( element );
						}
					} else if ( hovered.has( element ) ) {
						triggerLeaveEvent.push( element );

						hovered.delete( element );
					}
				} );

				triggerLeaveEvent.reverse().forEach( ( element ) => {
					element.dispatchEvent( new CustomEvent( 'mr-cursor:leave', {
						bubbles: true,
						cancelable: true,
					} ) );
				} );

				triggerEnterEvent.forEach( ( element ) => {
					element.dispatchEvent( new CustomEvent( 'mr-cursor:enter', {
						bubbles: true,
						cancelable: true,
					} ) );
				} );
			}, window, {
				passive: true,
			} );

			this.once( 'smooth-state:after', () => {
				this.unbind();
				this.bind();
			}, window );
		}

		bindPointer() {
			const mouseEventSelectors = [
				'a',
				'button',
				'.js-cursor-interaction',
			];

			const bindMouseEvent = ( event, handler ) => {
				mouseEventSelectors.forEach( ( selector ) => {
					this.on( `${event} ${selector}`, handler, document.body, {
						passive: true,
					} );
				} );
			};

			bindMouseEvent( 'mouseover', ( e, target ) => {
				this.el.classList.add( 'is-hover' );

				this.once( 'mouseleave', () => {
					this.el.classList.remove( 'is-hover' );
				}, target, {
					passive: true,
				} );
			} );

			if ( this.elements.sound ) {
				bindMouseEvent( 'click', () => {
					this.elements.sound.play();
				} );
			}

			this.on( 'mousemove', ( e ) => {
				const {
					clientX: x, clientY: y,
				} = e;
				this.move( x, y );
			}, window, {
				passive: true,
			} );
		}

		move( x, y ) {
			window.requestAnimationFrame( () => {
				Object.assign( this.el.style, {
					transform: `translate3d(${x}px, ${y}px, 0)`,
				} );
			} );
		}
	},
} );
