import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-grouped-grid', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.container = this.el.querySelector( '.js-grouped-grid-container' );
			this.elements.items = this.el.querySelectorAll( '.js-grouped-grid-item' );
		}

		bind() {
			if ( this.elements.container && this.elements.items ) {
				this.elements.items.forEach( ( item ) => {
					this.on( 'mouseenter', () => {
						this.blur( item.getAttribute( 'data-project-id' ) );
					}, item );

					this.on( 'mouseleave', () => {
						this.focus();
					}, item );
				} );

				this.on( 'mouseleave', () => {
					this.focus( null, true );
				}, this.elements.container );
			}
		}

		blur( id ) {
			if ( this.hideTimeout ) {
				clearTimeout( this.hideTimeout );
			}

			// unblur all items with id
			this.focus( id, true );

			// blur others that aren't blurred yet
			this.el.querySelectorAll( `
				.js-grouped-grid-item:not(.is-faded):not([data-project-id="${id}"])` )
				.forEach( ( item ) => {
					item.classList.add( 'is-faded' );
				} );
		}

		focus( id, force = false ) {
			if ( !force && this.hideTimeout ) {
				clearTimeout( this.hideTimeout );
			}

			let items;
			if ( id ) {
				items = this.el.querySelectorAll( `.js-grouped-grid-item.is-faded[data-project-id="${id}"]` );
			} else {
				items = this.el.querySelectorAll( '.js-grouped-grid-item.is-faded' );
			}

			if ( !items || !items.length ) {
				return;
			}

			const focus = () => {
				items.forEach( ( item ) => {
					item.classList.remove( 'is-faded' );
				} );
			};

			if ( force ) {
				focus();
			} else {
				this.hideTimeout = setTimeout( () => {
					focus();
				}, 112 );
			}
		}
	},
} );
