import { BaseController } from '../controllers/base';
import { parseHTML, renderNodes } from '../util/html';

interface PathEntry {
	from: string|null
	to: string
}

interface PreTransition<T extends HTMLElement> {
	path: PathEntry | null
	container: T|HTMLElement
}

interface Transition<T extends HTMLElement> {
	path: PathEntry | null
	container: T|HTMLElement
	fetched: {
		title: string
		content: Element
	}
}

class SmoothState extends BaseController<HTMLElement> {
	_path: Array<PathEntry> = [];

	get path(): Array<PathEntry> {
		return this._path;
	}

	get latestPathEntry(): PathEntry|null {
		if ( 0 < this.path.length ) {
			return this.path[this.path.length - 1];
		}

		return null;
	}

	addToPath( href: string ): void {
		// Make sure `href` is an absolute path from the / root of the current site
		let absolutePath = href.replace( window.location.origin, '' );
		if ( '/' !== absolutePath[0] ) {
			absolutePath = `/${absolutePath}`;
		}

		this._path = this._path || [];

		let from: string|null = null;

		if ( 0 < this._path.length ) {
			from = this._path[this._path.length - 1].to;
		}

		const pathEntry = {
			from: from,
			to: absolutePath,
		};

		this._path.push( pathEntry );

		return;
	}

	removeLatestFromPath(): void {
		this._path.pop();

		return;
	}

	pushState( href: string, title = '', addToPath = true ): void {
		const state = {
			href,
			title,
		};

		window.history.pushState( state, title, href );

		if ( addToPath ) {
			this.addToPath( href );
		}

		return;
	}

	replaceState( href: string, title = '', addToPath = true ): void {
		const state = {
			href: href,
			title: title,
		};

		window.history.replaceState( state, title, href );

		if ( addToPath ) {
			this.addToPath( href );
		}

		return;
	}

	override init(): void {
		this.replaceState( window.location.href, document.title );

		return;
	}

	override bind(): void {
		this.on( 'popstate', ( e: PopStateEvent | Event ) => {
			if ( !( e instanceof PopStateEvent ) ) {
				return;
			}

			if ( e.state && e.state.href ) {
				this.goTo( e.state.href, false ).catch( ( err ) => {
					console.warn( 'Could not run popstate to', e.state.href );
					console.warn( 'Error:', err );
				} );
			}
		}, window );

		this.on( 'click a:not(.js-mr-smooth-state-disable):not([href^="http"]):not([href^="#"])', ( e, target ) => {
			// Avoid cross-origin calls
			if ( !( target instanceof HTMLAnchorElement ) ) {
				return;
			}

			if ( !target.hostname || target.hostname !== window.location.hostname ) {
				return;
			}

			const href = target.getAttribute( 'href' );

			if ( !href ) {
				console.warn( 'Click on link without href' );

				return;
			}

			e.preventDefault();
			e.stopPropagation();

			this.goTo( href ).catch( ( err ) => {
				console.warn( 'Could not navigate to', href );
				console.warn( 'Error:', err );
			} );
		}, document.body );
	}

	goTo( href: string, pushState = true ): Promise<void> {
		return new Promise( ( resolve, reject ) => {
			window.dispatchEvent( new CustomEvent( 'smoothState:before' ) );

			document.body.classList.add( 'is-loading' );

			this.addToPath( href );

			const cancel = ( err: Error ) => {
				this.removeLatestFromPath();
				reject( err );
			};

			const latestPathEntry = this.latestPathEntry;

			let path = null;
			if ( latestPathEntry ) {
				path = Object.assign( latestPathEntry );
			}

			const preTransition : PreTransition<HTMLElement> = {
				container: this.el,
				path: path,
			};

			return this.onBefore( preTransition ).then( () => {
				fetch( href, {
					credentials: 'include',
				} ).then( ( res ) => {
					return res.text();
				} ).then( ( html ) => {
					const parsedHTML = parseHTML( html, 'mr-smooth-state' );
					const transition : Transition<HTMLElement> = {
						container: preTransition.container,
						path: preTransition.path,
						fetched: {
							title: parsedHTML.title || '',
							content: parsedHTML.content,
						},
					};

					window.dispatchEvent( new CustomEvent( 'smoothState:start' ) );

					this.onStart( transition ).then( () => {
						window.dispatchEvent( new CustomEvent( 'smoothState:ready' ) );

						this.onReady( transition ).then( () => {
							const {
								title: verifiedTitle, content: verifiedContent,
							} = transition.fetched;

							window.requestAnimationFrame( () => {
								renderNodes( verifiedContent, this.el );
								document.title = verifiedTitle;

								if ( pushState ) {
									// Don't add the state to the path
									this.pushState( href, verifiedTitle, false );
								}

								window.requestAnimationFrame( () => {
									document.body.classList.remove( 'is-loading' );

									window.dispatchEvent( new CustomEvent( 'smoothState:after' ) );

									// You can't cancel the transition after the pushState
									// So we also resolve inside the catch
									this.onAfter( transition ).then( () => {
										return resolve();
									} ).catch( () => {
										return resolve();
									} );
								} );
							} );
						} ).catch( ( err ) => {
							return cancel( err );
						} );
					} ).catch( ( err ) => {
						return cancel( err );
					} );
				} ).catch( ( err ) => {
					return cancel( err );
				} );
			} ).catch( ( err ) => {
				return cancel( err );
			} );
		} );
	}

	onBefore( transition: PreTransition<HTMLElement> ): Promise<PreTransition<HTMLElement>> {
		return Promise.resolve( transition );
	}

	onStart( transition: Transition<HTMLElement> ): Promise<Transition<HTMLElement>> {
		return Promise.resolve( transition );
	}

	onReady( transition: Transition<HTMLElement> ): Promise<Transition<HTMLElement>> {
		return Promise.resolve( transition );
	}

	onAfter( transition: Transition<HTMLElement> ): Promise<Transition<HTMLElement>> {
		return Promise.resolve( transition );
	}
}

const smoothState = {
	attributes: [],
	controller: SmoothState,
};

export default smoothState;
