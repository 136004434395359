import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
import dynamicProperties from '@mrhenry/wp--dynamic-properties';

defineCustomElement( 'mr-slideshow', {
	attributes: [
		{
			attribute: 'auto',
			type: 'int',
		},
		{
			attribute: 'current',
			type: 'int',
		},
	],
	controller: class extends BaseController {
		currentChangedCallback( from, to ) {
			this.emit( 'mr-slideshow:current', {
				current: to,
			} );

			this.render();
		}

		autoChangedCallback() {
			this.start();
		}

		start() {
			this.stop();

			if ( this.auto && 0 < this.auto ) {
				this.looper = setInterval( () => {
					this.next();
				}, this.auto );
			}
		}

		stop() {
			if ( this.looper ) {
				clearInterval( this.looper );
				this.looper = null;
			}
		}

		next() {
			if ( this.current < this.elements.items.length - 1 ) {
				this.current = this.current + 1;
			} else {
				this.current = this.elements.items.length - 1;
			}
		}

		previous() {
			if ( 0 < this.current ) {
				this.current = this.current - 1;
			} else {
				this.current = 0;
			}
		}

		resolve() {
			if ( 1 === this.el.querySelectorAll( '.js-slideshow-item' ).length ) {
				// Keep hanging, don't activate if empty
				return new Promise( () => {} );
			}

			return super.resolve();
		}

		init() {
			this.elements = {};
			this.elements.track = this.el.querySelector( '.js-slideshow-track' );
			this.elements.items = Array.from( this.el.querySelectorAll( '.js-slideshow-item' ) );

			if ( this.el.querySelector( '.js-slideshow-nav' ) ) {
				this.elements.nav = this.el.querySelector( '.js-slideshow-nav' );
			}

			this.dimensions = dynamicProperties( {
				marginLeft: () => {
					return parseInt( window
						.getComputedStyle( this.el.querySelector( '.js-slideshow-item' ), null )
						.marginLeft, 10 );
				},
			} );

			if ( !this.current ) {
				this.current = 0;
			}

			this.emit( 'mr-slideshow:current', {
				current: this.current,
			} );

			this.start();
		}

		bind() {
			this.on( 'keydown', ( e ) => {
				if ( 37 === e.keyCode ) {
					this.previous();
				} else if ( 39 === e.keyCode ) {
					this.next();
				}
			}, window, {
				passive: true,
			} );

			let resizeThrottle = false;
			this.on( 'resize', () => {
				if ( !resizeThrottle ) {
					resizeThrottle = true;
					this.scroll();

					setTimeout( () => {
						resizeThrottle = false;
						this.scroll();
					}, 250 );
				}
			}, window, {
				passive: true,
			} );

			this.on( 'click .js-slideshow-item', ( e, slideshowItem ) => {
				const clickX = e.clientX;
				const boundingBox = slideshowItem.getBoundingClientRect();
				const clickWithinRightHalf = 0.5 < ( ( clickX - boundingBox.left ) / boundingBox.width );

				if ( clickWithinRightHalf ) {
					this.next();
				} else {
					this.previous();
				}
			} );

			this.on( 'click .js-nav-item', ( e ) => {
				const index = this.elements.buttons.findIndex( ( item ) => {
					return item === e.target;
				} );

				if ( index !== this.current ) {
					e.preventDefault();
					this.current = index;
				}
			} );

			const touch = {
				startX: 0,
				threshold: 100,
				allowedTime: 400,
				startTime: 0,
			};

			this.on( 'touchstart', ( e ) => {
				touch.startX = e.changedTouches[0].screenX;
				touch.startTime = new Date().getTime();
			} );

			this.on( 'touchend', ( e ) => {
				const distX = e.changedTouches[0].screenX - touch.startX;
				if ( new Date().getTime() - touch.startTime <= touch.allowedTime &&
					Math.abs( distX ) >= touch.threshold ) {
					if ( 0 > distX ) {
						this.next();
					} else {
						this.previous();
					}
				}
			} );
		}

		render() {
			if ( !this.nav && this.elements.nav ) {
				this.nav = true;

				const fragment = document.createDocumentFragment();

				this.elements.buttons = this.elements.items.map( ( item, index ) => {
					const button = document.createElement( 'button' );
					button.setAttribute( 'href', index );
					button.classList.add( 'js-nav-item' );
					button.textContent = index + 1;
					Object.assign( item.dataset, {
						galleryIndex: index,
					} );
					fragment.appendChild( button );

					return button;
				} );

				this.elements.nav.appendChild( fragment );
			}

			this.elements.items.forEach( ( item, index ) => {
				if ( index === this.current ) {
					setTimeout( () => {
						item.classList.add( 'is-current' );
					}, 688 );
				} else {
					item.classList.remove( 'is-current' );
				}
			} );

			if ( this.elements.buttons ) {
				this.elements.buttons.forEach( ( button, index ) => {
					if ( index === this.current ) {
						button.classList.add( 'is-active' );
					} else {
						button.classList.remove( 'is-active' );
					}
				} );
			}

			this.scroll();
		}

		scroll() {
			if ( !this.elements || !this.elements.items ) {
				return;
			}

			if ( !this.elements.items[this.current] ) {
				return;
			}

			const position = this.elements.items[this.current].offsetLeft;
			const transform = `translateX(-${Math.round( position )}px)`;

			Object.assign( this.elements.track.style, {
				transform: transform,
				webkitTransform: transform,
			} );
		}

		destroy() {
			this.stop();
			super.destroy();
		}
	},
} );
