import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-media-hover', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.container = this.el.querySelector( '.js-media-hover' );
			this.elements.items = this.el.querySelectorAll( '.js-media-hover-item' );
			this.elements.links = this.el.querySelectorAll( '.js-media-hover-link' );

			// video.play() returns a Promise that we have to await before
			// being able to pause it again, otherwise browsers start to throw errors
			this.playPromises = new WeakMap();
		}

		bind() {
			const renderItem = ( item ) => {
				const video = item.querySelector( '.tpl-media-hover-video' );
				const poster = item.querySelector( '.tpl-media-hover-image' );

				if ( !video && !poster ) {
					return;
				}

				const target = video?.parentNode || poster.parentNode;
				let innerHTML = '';

				if ( video ) {
					innerHTML = `${innerHTML}${video.innerHTML}`;
				} else if ( poster ) {
					innerHTML = `${innerHTML}${poster.innerHTML}`;
				}

				target.innerHTML = innerHTML;
			};

			if ( 'ontouchstart' in window ) {
				if ( this.elements.items ) {
					this.elements.items.forEach( ( item ) => {
						this.once( 'mr-cursor:enter', () => {
							renderItem( item );
						}, item, {
							passive: true,
							capture: true,
						} );
					} );
				}

				if ( this.elements.links ) {
					this.elements.links.forEach( ( link ) => {
						this.on( 'mr-cursor:enter', () => {
							this.show( link );
						}, link );

						this.on( 'mr-cursor:leave', () => {
							this.hide( link );
						}, link );
					} );

					this.on( 'mr-cursor:leave', ( e, target ) => {
						if ( target === this.elements.container ) {
							this.clear();
						}
					}, this.elements.container );
				}
			}

			if ( !( 'ontouchstart' in window ) ) {
				if ( this.elements.items ) {
					this.elements.items.forEach( ( item ) => {
						this.once( 'mouseenter', () => {
							renderItem( item );
						}, item, {
							passive: true,
							capture: true,
						} );
					} );
				}

				if ( this.elements.links ) {
					this.elements.links.forEach( ( link ) => {
						this.on( 'mouseenter', () => {
							this.show( link );
						}, link );

						this.on( 'mouseleave', () => {
							this.hide( link );
						}, link );
					} );
				}
			}
		}

		show( link ) {
			document.body.classList.add( 'has-media-hover' );
			link.parentNode.classList.add( 'is-active' );

			const video = link.parentNode.querySelector( '.js-media-hover-video' );
			if ( !video ) {
				return;
			}

			const playPromise = new Promise( ( resolve ) => {
				const p = video.play();
				if ( !p ) {
					resolve();

					return;
				}

				p.then( () => {
					resolve();
				} ).catch( ( err ) => {
					console.warn( err );
					video.setAttribute( 'controls', '' );
					resolve();
				} );
			} );

			this.playPromises.set( video, playPromise );
		}

		hide( link ) {
			document.body.classList.remove( 'has-media-hover' );

			const video = link.parentNode.querySelector( '.js-media-hover-video' );

			if ( video ) {
				const playPromise = this.playPromises.get( video );

				if ( playPromise ) {
					playPromise.then( () => {
						video.pause();
						this.playPromises.delete( video );
					}, () => {
						this.playPromises.delete( video );
					} );
				}
			}

			link.parentNode.classList.remove( 'is-active' );
		}

		clear() {
			this.elements.links.forEach( ( link ) => {
				this.hide( link );
			} );
		}
	},
} );
